import React from 'react';
import styled, { keyframes } from 'styled-components';

const BounceDelay = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
    -webkit-transform: scale(0);
  } 40% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
`;

const LoadingContainer = styled.div`
  padding: 0.5em;
  position: relative;
  text-align: center;
  max-height: 14px;
  z-index: 1000;
  &:hover,
  &:focus {
    background-color: transparent;
  }
`;

const BounceItem = styled.div`
  animation: ${BounceDelay} 1.4s infinite ease-in-out both;
  background-color: ${({ theme }): string => theme.palette.primary};
  border-radius: 100%;
  display: inline-block;
  height: 15px;
  width: 15px;
  -webkit-animation: ${BounceDelay} 1.4s infinite ease-in-out both;
`;

const BounceOne = styled(BounceItem)`
  animation-delay: -0.32s;
  -webkit-animation-delay: -0.32s;
`;

const BounceTwo = styled(BounceItem)`
  animation-delay: -0.16s;
  -webkit-animation-delay: -0.16s;
`;

interface IBounceLoaderProps {
  loading: boolean;
}

const BounceLoader = ({ loading }: IBounceLoaderProps): JSX.Element | null => {
  if (!loading) return null;
  return (
    <LoadingContainer data-testid="bounce-loader">
      <BounceOne className="spinner" />
      <BounceTwo className="spinner" />
      <BounceItem className="spinner" />
    </LoadingContainer>
  );
};
export default BounceLoader;
