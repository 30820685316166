import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';

export const Label = styled.label`
  color: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.palette.white;
  }};
  font-size: 12px;
  font-weight: normal;
  left: 16px;
  line-height: 24px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 24px;
  transition: 0.1s all ease-in-out;

  .error {
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.error;
    }};
  }
`;
