import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';

interface INavButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: ReactNode;
}

const NavButton = ({ children, onClick }: INavButtonProps): JSX.Element => {
  return (
    <Button type="button" onClick={onClick}>
      {children}
    </Button>
  );
};

NavButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NavButton;

const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.spacing.spacingSizes.xs;
  }};
  font-size: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.font.fontSizes.md;
  }};

  &:focus {
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.primary;
    }};
    outline: none;
  }

  &:hover {
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.primary;
    }};
  }
`;
