import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';

export const InputWrapper = styled.div`
  box-sizing: border-box;
  margin-bottom: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.spacing.spacingSizes.sm;
  }};
  position: relative;
`;
