import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';
import { deriveMaxWidth } from '../utils';

interface IHeadingContainerProps {
  size: string;
}

export const HeadingContainer = styled.div<IHeadingContainerProps>`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: ${({ theme }: ThemeProps<ITheme>): string => theme.spacing.spacingSizes.xl};
  max-width: ${deriveMaxWidth};
  min-height: ${({ theme }: ThemeProps<ITheme>): string => theme.spacing.spacingSizes.xl};
  z-index: 3000;
`;
