import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';

export const CalendarContainer = styled.div`
  background-color: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.palette.white;
  }};
  border: ${({ theme }: ThemeProps<ITheme>): string => {
    return `1px solid ${theme.palette.grayLight}`;
  }};
  display: inline-block;
  left: 0;
  margin-bottom: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.spacing.spacingSizes.lg;
  }};
  position: absolute;
  top: 54px;
  z-index: 1000;
`;
