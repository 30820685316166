import styled from 'styled-components';

const SnackbarContainer = styled.div`
  left: 50%;
  max-width: 30rem;
  position: fixed;
  top: 1rem;
  transform: translateX(-50%);
  z-index: 1000;
  width: 30rem;
`;

export default SnackbarContainer;
