import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';

export const SelectWrapper = styled.div`
  &:after {
    border-bottom: ${({ theme }: ThemeProps<ITheme>): string => {
      return `solid 1px  ${theme.palette.grayLightest}`;
    }};
    content: '>';
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.grayDark;
    }};
    font-size: 16px;
    height: 34px;
    padding: 0 10px 0 10px;
    pointer-events: none;
    position: absolute;
    right: 16px;
    top: 8px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
`;
