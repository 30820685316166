import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';
import { HiddenCheckbox } from './HiddenCheckbox';
import { Icon } from './Icon';

interface IStyledCheckboxProps extends ThemeProps<ITheme> {
  checked: boolean;
}

export const StyledCheckbox = styled.div`
  background: ${({ theme, checked }: IStyledCheckboxProps): string =>
    checked ? theme.palette.primary : theme.palette.white};
  border: ${({ theme }: ThemeProps<ITheme>): string => {
    return `1px solid ${theme.palette.grayLight}`;
  }};
  border-radius: 3px;
  display: inline-block;
  height: 16px;
  transition: all 150ms;
  width: 16px;

  ${HiddenCheckbox}:hover + & {
    border: ${({ theme }: ThemeProps<ITheme>): string => {
      return `1px solid ${theme.palette.primary}`;
    }};
  }

  ${Icon} {
    visibility: ${(props: IStyledCheckboxProps): string => (props.checked ? 'visible' : 'hidden')};
  }
`;
