import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';

export const Label = styled.label`
  color: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.palette.grayDarkest;
  }};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  padding: 5px 10px 5px 0;
  position: relative;
`;
