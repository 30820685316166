import { ITheme } from '../interfaces/ITheme';

const DefaultTheme: ITheme = {
  borderRadius: {
    xsmall: '0.1875rem', // 3px
    small: '0.3125rem', // 5px
    medium: '0.625rem', // 10px
    large: '1rem', // 16px
    xlarge: '1.5625rem', // 25px
  },
  boxShadow: {
    small: '0 0 3px rgba(0, 0, 0, .20)',
    large: '0 3px 20px rgba(0, 0, 0, .20)',
  },
  font: {
    fontSizes: {
      xs: '0.75rem', // 12px
      sm: '0.875rem', // 14px
      md: '1rem', // 16px
      lg: '1.125rem', // 18px
      xl: '1.25rem', // 20px
      xxl: '1.5rem', // 24px
    },
    headlineSizes: {
      h1: '2.5rem', // 40px
      h2: '2.25rem', // 36px
      h3: '2rem', // 32px
      h4: '1.5rem', // 24px
      h5: '1.25rem', // 20px
      h6: '1rem', // 16px
    },
    fontFamilies: {
      body: '"Roboto", Arial, Helvetica, sans-serif',
      heading: '"Roboto", Arial, Helvetica, sans-serif',
    },
  },
  palette: {
    primaryLightest: '#a7c4ff',
    primaryLight: '#7a96ff',
    primary: 'hsl(224, 76%, 57%)',
    primaryDark: 'hsl(224, 76%, 37%)',
    error: '#ff3e28',
    errorLight: '#fdcab9',
    errorSecondary: '#fe5f55',
    errorSecondaryDark: '#ca2f20',
    grayWhite: '#fbfbfb',
    graySecondary: '#8f99a4',
    success: 'hsla(166, 100%, 26%, 1)',
    grayLightest: 'hsl(0,0%,90%)',
    grayLight: 'hsl(0,0%,70%)',
    grayDark: 'hsl(0,0%,40%)',
    grayDarker: 'hsl(0,0%,30%)',
    grayDarkest: 'hsl(0,0%, 20%)',
    warning: '#ff8d07',
    white: 'hsl(230,0%,98%)',
    black: 'hsl(230,68%,16%)',
  },
  spacing: {
    spacingSizes: {
      xxxs: '0.125rem', // 2px
      xxs: '0.25rem', // 4px
      xs: '0.5rem', // 8px
      sm: '0.75rem', // 12px
      md: '1rem', // 16px
      lg: '1.5rem', // 24px
      xl: '2rem', // 32px
      xxl: '2.5rem', // 40px
      xxxl: '3rem', // 48px
    },
    layoutSizes: {
      xxs: '1rem', // 16px
      xs: '1.5rem', // 24px
      sm: '2rem', // 32px
      md: '3rem', // 48px
      lg: '4rem', // 64px
      xl: '6rem', // 96px
      xxl: '10rem', // 160px
    },
  },
};

export default DefaultTheme;
