import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';

export const Icon = styled.span`
  border: ${({ theme }: ThemeProps<ITheme>): string => {
    return `1px solid ${theme.palette.grayLight}`;
  }};
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  left: 0;
  margin-right: 5px;
  position: relative;
  width: 14px;
  vertical-align: middle;

  &::after {
    background-color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.primary;
    }};
    border-radius: 50%;
    content: '';
    display: block;
    height: 0;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transition: all 110ms;
    width: 0;
  }
`;
