import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as ParentProvider } from 'styled-components';
import { ITheme } from './interfaces/ITheme';
import { IResponsiveDevice } from './interfaces/IResponsiveDevice';
import { DeviceSizes } from './enum/DeviceSizes';
import DefaultTheme from './config/DefaultTheme';

interface IProps {
  children: ReactNode;
  theme?: ITheme;
}

const ThemeProvider = ({ children, theme }: IProps): JSX.Element => {
  const providerTheme = theme ? theme : DefaultTheme;

  return <ParentProvider theme={providerTheme}>{children}</ParentProvider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object,
};

ThemeProvider.displayName = 'ThemeProvider';

export default ThemeProvider;

export const ResponsiveDeviceMinWidth: IResponsiveDevice = {
  mobileS: `(min-width: ${DeviceSizes.mobileS})`,
  mobileM: `(min-width: ${DeviceSizes.mobileM})`,
  mobileL: `(min-width: ${DeviceSizes.mobileL})`,
  tablet: `(min-width: ${DeviceSizes.tablet})`,
  laptop: `(min-width: ${DeviceSizes.laptop})`,
  laptopL: `(min-width: ${DeviceSizes.laptopL})`,
  desktop: `(min-width: ${DeviceSizes.desktop})`,
  desktopL: `(min-width: ${DeviceSizes.desktop})`,
};

export const ResponsiveDeviceMaxWidth: IResponsiveDevice = {
  mobileS: `(max-width: ${DeviceSizes.mobileS})`,
  mobileM: `(max-width: ${DeviceSizes.mobileM})`,
  mobileL: `(max-width: ${DeviceSizes.mobileL})`,
  tablet: `(max-width: ${DeviceSizes.tablet})`,
  laptop: `(max-width: ${DeviceSizes.laptop})`,
  laptopL: `(max-width: ${DeviceSizes.laptopL})`,
  desktop: `(max-width: ${DeviceSizes.desktop})`,
  desktopL: `(max-width: ${DeviceSizes.desktop})`,
};

export { ITheme };

export { DeviceSizes };
