import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';

export const RadioGroupWrapper = styled.div`
  &.disabled label {
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.grayLight;
    }};
  }

  &.disabled span {
    &::after {
      background-color: ${({ theme }: ThemeProps<ITheme>): string => {
        return theme.palette.grayLight;
      }};
    }
  }

  &.error label {
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.error;
    }};
  }

  &.error span {
    border: ${({ theme }: ThemeProps<ITheme>): string => {
      return `1px solid ${theme.palette.error}`;
    }};
  }
`;
