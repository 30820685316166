import React, { useRef, useEffect } from 'react';
import styled, { ThemeProps } from 'styled-components';
import { useMonth, FirstDayOfWeek } from '@datepicker-react/hooks';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';
import Day from './Day';

interface IMonthProps {
  year: number;
  month: number;
  firstDayOfWeek: FirstDayOfWeek;
  onDaySelected: () => void;
}
const Month = ({ year, month, firstDayOfWeek, onDaySelected }: IMonthProps): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });

  useEffect(() => {
    ref.current?.scrollIntoView();
  }, []);

  return (
    <div ref={ref}>
      <MonthLabel>
        <strong>{monthLabel}</strong>
      </MonthLabel>
      <WeekGrid>
        {weekdayLabels.map((dayLabel) => (
          <WeekGridItem key={dayLabel}>{dayLabel}</WeekGridItem>
        ))}
      </WeekGrid>
      <DayGrid>
        {days.map((day, index) => {
          if (typeof day === 'object') {
            return (
              <Day key={day.date.toString()} date={day.date} dayLabel={day.dayLabel} onDaySelected={onDaySelected} />
            );
          }
          return <div key={index} />;
        })}
      </DayGrid>
    </div>
  );
};

export default Month;

const MonthLabel = styled.div`
  margin: 0 0 16px;
  text-align: center;
`;

const WeekGrid = styled.div`
  display: grid;
  font-size: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.font.fontSizes.sm;
  }};
  justify-content: center;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 10px;
`;

const WeekGridItem = styled.div`
  text-align: center;
`;

const DayGrid = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(7, 1fr);
`;
