import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';
import { SwitchLabel } from './SwitchLabel';

export const StyledSwitch = styled.input`
  border-radius: 15px;
  cursor: pointer;
  height: 18px;
  opacity: 0;
  width: 35px;
  z-index: 1;

  &:checked + ${SwitchLabel} {
    background-color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.white;
    }};
    border: ${({ theme }: ThemeProps<ITheme>): string => {
      return `1px solid ${theme.palette.primaryLight}`;
    }};
    &::after {
      border: none;
      background-color: ${({ theme }: ThemeProps<ITheme>): string => {
        return theme.palette.primary;
      }};
      border: ${({ theme }: ThemeProps<ITheme>): string => {
        return `1px solid ${theme.palette.primary}`;
      }};
      border-radius: 50%;
      content: '';
      display: block;
      height: 16px;
      margin-left: 18px;
      margin-top: -4px;
      transition: 0.2s;
      width: 16px;
    }
  }
`;
