import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';

export const SelectInput = styled.select`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.palette.white;
  }};
  border: ${({ theme }: ThemeProps<ITheme>): string => {
    return `solid 1px  ${theme.palette.grayLightest}`;
  }};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.palette.grayDarkest;
  }};
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  height: 50px;
  padding-left: 5px;
  position: relative;
  transition: 0.3s all;
  width: 100%;
  text-indent: 7px;

  &.disabled {
    background-color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.grayLight;
    }};
  }

  &:focus {
    outline: none;
    border: ${({ theme }: ThemeProps<ITheme>): string => {
      return `1px solid ${theme.palette.primary}`;
    }};
  }

  &.error {
    border: ${({ theme }: ThemeProps<ITheme>): string => {
      return `2px solid ${theme.palette.error}`;
    }};
  }
`;
