import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';

export const Input = styled.input`
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 4px 20px 0px transparent;
  color: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.palette.grayDarkest;
  }};
  font-size: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.font.fontSizes.sm;
  }};
  font-weight: 400;
  height: 50px;
  line-height: normal;
  outline: none;
  padding: 0px 16px;
  position: relative;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
  width: 100%;
  -webkit-appearance: none;

  &::-webkit-input-placeholder {
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.grayDark;
    }};
  }

  &::-moz-placeholder {
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.grayDark;
    }};
  }

  &:-ms-input-placeholder {
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.grayDark;
    }};
  }

  &:-moz-placeholder {
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.grayDark;
    }};
  }
`;
