import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';

export const Field = styled.div`
  background-color: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.palette.white;
  }};
  border: ${({ theme }: ThemeProps<ITheme>): string => {
    return `solid 1px ${theme.palette.grayLightest}`;
  }};
  border-radius: 4px;
  box-sizing: border-box;
  height: 50px;
  position: relative;
  transition: 0.3s all;
  width: 100%;

  &.disabled {
    background-color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.grayLight;
    }};
  }

  &.focussed {
    border: ${({ theme }: ThemeProps<ITheme>): string => {
      return `1px solid ${theme.palette.primary}`;
    }};
  }

  &.focussed input,
  &.value input {
    padding: 22px 16px 8px 16px;
    &::-webkit-input-placeholder {
      color: ${({ theme }: ThemeProps<ITheme>): string => {
        return theme.palette.white;
      }};
    }

    &::-moz-placeholder {
      color: ${({ theme }: ThemeProps<ITheme>): string => {
        return theme.palette.white;
      }};
    }

    &:-ms-input-placeholder {
      color: ${({ theme }: ThemeProps<ITheme>): string => {
        return theme.palette.white;
      }};
    }

    &:-moz-placeholder {
      color: ${({ theme }: ThemeProps<ITheme>): string => {
        return theme.palette.white;
      }};
    }
  }

  &.focussed input + label,
  &.value input + label {
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.primaryLight;
    }};
    opacity: 1;
    top: 4px;
  }

  &.value input + label {
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.primaryLight;
    }};
  }

  &.error input + label {
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.error;
    }};
    opacity: 1;
    top: 4px;
  }

  &.error {
    border: ${({ theme }: ThemeProps<ITheme>): string => {
      return `2px solid ${theme.palette.error}`;
    }};
  }
`;
