import React, { ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { ValidationOptions } from 'react-hook-form';
import { RadioWrapper } from './components/RadioWrapper';
import { Icon } from './components/Icon';
import { Input } from './components/Input';
import { Label } from './components/Label';

interface IProps {
  name: string;
  label?: string;
  className?: string;
  value?: string | number;
  checked?: boolean;
  disabled?: boolean;
  validation?: ValidationOptions;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  register?: (e: unknown, v: unknown) => void;
}

const Radio = ({
  name,
  label,
  className,
  value,
  checked,
  disabled,
  onChange,
  register,
  validation,
  ...rest
}: IProps): JSX.Element => {
  return (
    <RadioWrapper>
      <Label className={className}>
        <Input
          name={name}
          type="radio"
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          ref={(e): void => {
            if (register) {
              register(e, validation);
            }
          }}
          data-testid="radio-input"
          {...rest}
        />
        <Icon />
        {label}
      </Label>
    </RadioWrapper>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  validation: PropTypes.object,
  onChange: PropTypes.func,
  register: PropTypes.func,
};

Radio.displayName = 'Radio';

export default Radio;
