import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';

export const SwitchLabel = styled.label`
  background: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.palette.grayLightest;
  }};
  border-radius: 15px;
  cursor: pointer;
  height: 10px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 35px;
  z-index: -1;

  &::after {
    background: #ffffff;
    border: ${({ theme }: ThemeProps<ITheme>): string => {
      return `1px solid ${theme.palette.grayLightest}`;
    }};
    border-radius: 50%;
    content: '';
    display: block;
    height: 16px;
    margin-top: -4px;
    width: 16px;
    transition: 0.2s;
  }
`;
