import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';

export const LabelText = styled.span`
  bottom: 2px;
  color: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.palette.grayDarkest;
  }};
  font-size: 14px;
  left: 5px;
  margin-right: 12px;
  position: relative;
`;
