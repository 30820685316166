import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';

export const CheckboxContainer = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: middle;

  &.disabled div {
    background-color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.grayLight;
    }};
  }

  &.disabled span {
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.grayLight;
    }};
  }

  &.error div {
    border: ${({ theme }: ThemeProps<ITheme>): string => {
      return `1px solid ${theme.palette.error}`;
    }};
  }

  &.error span {
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.error;
    }};
  }

  &.error a {
    color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.error;
    }};
  }
`;
