import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';
import { IMenuItem } from '../interfaces/IMenuItem';

const MenuItem = styled.div<IMenuItem>`
  cursor: pointer;
  background-color: ${({ theme, isSelected }: IMenuItem): string => {
    return isSelected ? theme.palette.grayLightest : 'transparent';
  }};
  border-bottom: ${({ theme }: ThemeProps<ITheme>): string => {
    return `solid 1px ${theme.palette.grayLightest}`;
  }};
  padding: 1em;

  &:last-child {
    border-bottom: none;
  }
`;

export default MenuItem;
