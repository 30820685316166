import React, { ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { ValidationOptions } from 'react-hook-form';
import styled, { ThemeProps } from 'styled-components';
import { ITheme } from 'providers/ThemeProvider/interfaces/ITheme';
import { InputWrapper } from '../InputWrapper';

interface IProps {
  name: string;
  label: string;
  className?: string;
  disabled?: boolean;
  value?: string | number;
  error?: string | undefined;
  validation?: ValidationOptions;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  register?: (e: unknown, v: unknown) => void;
}

const TextArea = ({
  name,
  label,
  className,
  disabled,
  value,
  error,
  validation,
  onChange,
  register,
  ...rest
}: IProps): JSX.Element => {
  const errorClass = error ? 'error' : '';
  return (
    <InputWrapper>
      <StyledTextArea
        name={name}
        placeholder={label}
        disabled={disabled}
        ref={(e): void => {
          if (register) {
            register(e, validation);
          }
        }}
        value={value}
        onChange={onChange}
        className={`${className} ${errorClass}`}
        autoComplete="off"
        aria-label={name}
        data-testid="text-area"
        {...rest}
      />
    </InputWrapper>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  validation: PropTypes.object,
  onChange: PropTypes.func,
  register: PropTypes.func,
};

TextArea.displayName = 'TextArea';

export default TextArea;

const StyledTextArea = styled.textarea`
  background-color: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.palette.white;
  }};
  border: ${({ theme }: ThemeProps<ITheme>): string => {
    return `solid 1px ${theme.palette.grayLightest}`;
  }};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.palette.grayDarkest;
  }};
  font-family: inherit;
  font-size: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.font.fontSizes.sm;
  }};
  font-weight: 400;
  min-height: 150px;
  line-height: normal;
  padding: ${({ theme }: ThemeProps<ITheme>): string => {
    return theme.spacing.spacingSizes.md;
  }};
  position: relative;
  resize: vertical;
  transition: 0.3s all;
  width: 100%;

  :disabled {
    background-color: ${({ theme }: ThemeProps<ITheme>): string => {
      return theme.palette.grayLight;
    }};
  }

  :focus {
    border: ${({ theme }: ThemeProps<ITheme>): string => {
      return `1px solid ${theme.palette.primary}`;
    }};
    outline: none;
  }

  &.error {
    border: ${({ theme }: ThemeProps<ITheme>): string => {
      return `2px solid ${theme.palette.error}`;
    }};
  }
`;
